import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as staticTextKey from '../../utils/translation-variable';
import userLogo from '../static/imgs/userLogo.png';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { TextField, Button, Modal, IconButton, Typography, Divider} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FooterMenu } from '../components/footer-menu';
import { AppLayout } from '../components/app-layout';
import theme from '../utils/theme';
import { makeStyles } from '@material-ui/core/styles';
import { Cookies } from 'react-cookie';

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    background: 'white',
    width: 440,
    height: 470,
    boxShadow: theme.shadows[5],
    
  }
}));

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    height: '150px'
  };
}

export function Totp(props) {

  const [otp, setOtp] = useState();
  const [base64String, setBase64String] = useState("");
  const [code, setCode] = useState("");
  const [error,setError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const http = Http.getInstance();
  var cookies = new Cookies();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const authorised_user = cookies.get("authorised_user");
  const dataSource = dataSources();
  const mfa_type = (props.location.mfa_type !== undefined || props.location.mfa_type !== null) && props.location.mfa_type;

  useEffect(() => {
    if(authorised_user === "true"){
      getCodes();
    }
  }, []);

  const getCodes = () => {
    http.postData(`${dataSource.qrCode}`, {}, '')
    .then(res => {
      if (res.status === 200) {
        setBase64String(res.data.qr_code);
        setCode(res.data.shared_secret);
      }
      else if(res.status === 409) {
        http.getData(`${dataSource.qrCode}`, '', '')
        .then(res => {
          if (res.status === 200) {
            setBase64String(res.data.qr_code);
            setCode(res.data.shared_secret);
          }
        })
        .catch(error => {
          console.log("error",error);
        });
      }
    })
    .catch(res => {
      console.log("error",res);
    });
  }

  const handleOtp = (event) => {
    if(event.target.value.length === 0){
      setError(false);
    }
    setOtp(event.target.value);
  };

  const activateTotp = () => {
    const code = {"code" : otp}
    http.postData(`${dataSource.validateTotp}`, code , '')
    .then(res => {
    if (res.status === 200) {
      if(res.data.valid === true) {
        //setOpenAlert(true);
        setError(false);
        const user = {
                      mfa_enabled: true,
                      mfa_type: mfa_type,
                      totp_verified: true
                     };
        http.patchData(`${dataSource.users}`, user , '')
        .then(res => {
            if (res.status === 200) {
              cookies.set('mfa_enabled', true, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              cookies.set('mfa_type', mfa_type, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              cookies.set('totp_verified', true, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              setOpenAlert(true);
              //setTimeout(()=>{window.location.href="/launcher"},3000);
            }
        })
        .catch(error => {
            console.log(error);
        });
      }
      else if(res.data.valid === false) {
        console.log("invalid otp");
        setOpenAlert(false);
        setError(true);
      }
    }
    else if(res.status === 400) {
      setOpenAlert(false);
      setError(true);
    }
    })
    .catch(error => {
      console.log(error);
    });
  }

  const handleClose = () => {
    setOpenAlert(false);
    window.location.href = "/launcher";
  }

  if(authorised_user === "true"){
    return (
      <React.Fragment>
          <ThemeProvider theme={theme}>
              <div dir="ltr">
              
                  <AppLayout
                  apps={JSON.parse(sessionStorage.getItem("apps"))}
                  errorDisplay={sessionStorage.getItem("errorDisplay") === "false" ? false : true}
                  errorResponse={sessionStorage.getItem("error")}
                  />
  {/* 
                  { verified && <Alert 
                                icon={false} 
                                className="successNotification"
                                onClose={() => {setVerified(false)}}>
                                  You are successfully authenticated!
                                </Alert> 
                  } */}

                              <Modal
                                open={openAlert}
                                onClose={handleClose}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description">
                                <div style={modalStyle} className={classes.modal}>
                                  <div className={'dialogHeader'}>
                                    <Typography color="textPrimary" className={'dialogTitle'}>
                                      SUCCESS
                                    </Typography>
                                    <IconButton onClick={handleClose} className={'closeButton'}>
                                      <CloseIcon />
                                    </IconButton>
                                  </div>
                                  <Divider />
                                  <div className={'successMsg'}> You are successfully authenticated! </div>
                                  <Button 
                                  variant="contained"
                                  color="primary"
                                  onClick={()=>{handleClose()}}
                                  className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable actionBtn"
                                  >
                                    OK
                                  </Button> 
                                </div>
                              </Modal>

                  <div className = "totpRoot">
                      <h5 className = "totpTitle">Multifactor Authentication setup for authenticator app verification</h5>

                      <div className = "totp">
                          <div className = "codeSection">

                              <h5>Scan code or enter key</h5>
                              <div>Scan the code below with your authenticator app to add this account. Some authenticator apps also allow you to type in the text version instead</div>
                              
                              {base64String.length > 0 && <img alt="qrcode" height = "200" width = "200" src={`data:image/png;base64,${base64String}`}/>}
                              {code.length > 0 && <div className="activationCode">{code}</div>}

                          </div>

                          <div className = "otpVerifySection">
                              
                              <img alt="user" className = "userLogo" src={userLogo} />
                              <h5>Enter code from authenticator app </h5>
                              <div>Enter the code from your authenticator app to verify and activate multi-factor authentication for this account</div>
                              
                              <TextField
                              className="otpField"
                              variant="outlined"
                              fullWidth
                              value={otp}
                              required
                              onChange={(e) => handleOtp(e)}
                              onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    e.preventDefault();
                                    activateTotp();
                                }
                              }}
                            />

                              {error && <div className="otpErrorMsg">Invalid user authentication code. Please retry!</div>}

                              <Button 
                              variant="contained"
                              color="primary"
                              onClick={()=>{activateTotp()}}
                              className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn"
                              >
                                Activate
                              </Button>                   
                          </div>
                      </div>
                  </div>

                  <FooterMenu/>

              </div>
          </ThemeProvider>
      </React.Fragment>
    );
  }
  else {
    window.location.href = "/";
  }
}
