import React, { useState, useEffect, useRef } from 'react';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { Redirect, useHistory } from 'react-router-dom';
import { AppLayout } from '../components/app-layout';
import { ThemeProvider } from '@material-ui/core/styles';
import { Button, Select, MenuItem, Modal, IconButton, Typography, Divider} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Cookies } from 'react-cookie';
import { FooterMenu } from '../components/footer-menu';
import * as staticTextKey from '../utils/translation-variable';
import theme from '../utils/theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    modal: {
      position: 'absolute',
      background: 'white',
      width: 440,
      height: 470,
      boxShadow: theme.shadows[5],
      
    }
  }));
  
  function getModalStyle() {
    return {
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
      height: '150px'
    };
  }

export function SettingsPage(props) {
//   const totp = useRef(null);
//   const email = useRef(null);
//   const sms = useRef(null);
  const cookies = new Cookies();
  const http = Http.getInstance();
  const dataSource = dataSources();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const history = useHistory();
  const authorised_user = cookies.get("authorised_user");
  const mfa_enabled = cookies.get("mfa_enabled");
  const mfa_method = cookies.get("mfa_type");
  const [enabled, setEnabled] = useState(cookies.get("mfa_enabled") === "false" ? false : true);
  const [mfaTypes, setMfaTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(mfa_method);
  const [userModified, setUserModified] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    const mfa_type = cookies.get("mfa_type").type;
    if(enabled && mfaTypes){
        http.getData(`${dataSource.mfaTypes}`,'','')
        .then(response => {
              setMfaTypes(response.data);
                // if(mfa_type.toLowerCase() === "totp"){
                //     totp.current.checked = "checked";
                // }
                // else if(mfa_type.toLowerCase() === "email"){
                //     email.current.checked = "checked";
                // }
                // else if(mfa_type.toLowerCase() === "sms"){
                //     sms.current.checked = "checked";
                // }
          }).catch( error => {
              console.log("error",error);
          })
    }
  }, [enabled]);

  const mfaSelection = (data) => {
    setEnabled(data);
    if(data === false){
        const user = {
            mfa_enabled: false,
            // mfa_type: []
         };
        http.patchData(`${dataSource.users}`, user , '')
        .then(res => {
        if (res.status === 200) {
            cookies.set('mfa_enabled', false, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
            });
        }
        })
        .catch(error => {
            console.log(error);
        });
    }
    else{
        if(mfa_method !== undefined && mfa_method !== null && mfa_method !== "null" && mfa_method !== "undefined" ){
            const user = {
                mfa_enabled: true,
            };
            http.patchData(`${dataSource.users}`, user , '')
            .then(res => {
            if (res.status === 200) {
                cookies.set('mfa_enabled', true, {
                    path: '/',
                    domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                });
            }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
  }

  const activateMFA = (type) => {
    // cookies.set('mfa_enabled', enabled, {
    //         path: '/',
    //         domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    // });
    // cookies.set('mfa_type', type, {
    //         path: '/',
    //         domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    // });
    setSelectedType(type);
    setUserModified(true);
    // const user = {
    //                 mfa_enabled: enabled,
    //                 mfa_type: {"mfa_id": selectedType.mfa_id , "type": selectedType.type}
    //              };
    // http.patchData(`https://iam.bniportal.dev/users`, user , '')
    // .then(res => {
    //     if (res.status === 200) {
    //       console.log("user mfa updated",res);
    //       cookies.set('mfa_enabled', enabled, {
    //         path: '/',
    //         domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    //       });
    //       setUserModified(true);
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }

  const handlePreferedMethod = (event) => {
    const preferedMethod = JSON.parse(event.target.value);
    setSelectedType(preferedMethod);
    const user = {
                    mfa_type: preferedMethod,
                    mfa_enabled: true
                };
    http.patchData(`${dataSource.users}`, user , '')
    .then(res => {
        if (res.status === 200) {
          console.log("user mfa method updated");
          cookies.set('mfa_enabled', true, {
            path: '/',
            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
          });
          cookies.set('mfa_type', preferedMethod, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
          });
          setOpenAlert(true);
        }
     })
    .catch(error => {
            console.log(error);
    });
  }

  const onCancelAction = () => {
    window.location.href = "/launcher";
  }

  
  if(authorised_user === "true"){
    if(userModified) {
        history.push({
            pathname: selectedType.type.toLowerCase(),
                mfa_type: selectedType
        });
        return (
            // <Redirect
            //     to={{
            //         pathname: selectedType.type.toLowerCase(),
            //         mfa_type: selectedType
            //     }}
            // />
            ""
        );
    }
    else {
        return (
                <React.Fragment>
                <ThemeProvider theme={theme}>
                    <div dir="ltr">
                    <div>
                        <AppLayout
                        apps={JSON.parse(sessionStorage.getItem("apps"))}
                        errorDisplay={sessionStorage.getItem("errorDisplay") === "false" ? false : true}
                        errorResponse={sessionStorage.getItem("error")}
                        />

                                <Modal
                                open={openAlert}
                                onClose={()=>{setOpenAlert(false)}}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description">
                                <div style={modalStyle} className={classes.modal}>
                                  <div className={'dialogHeader'}>
                                    <Typography color="textPrimary" className={'dialogTitle'}>
                                      SUCCESS
                                    </Typography>
                                    <IconButton onClick={()=>{setOpenAlert(false)}} className={'closeButton'}>
                                      <CloseIcon />
                                    </IconButton>
                                  </div>
                                  <Divider />
                                  <div className={'successMsg'}> MFA method is updated successfully </div>
                                  <Button 
                                  variant="contained"
                                  color="primary"
                                  onClick={()=>{setOpenAlert(false)}}
                                  className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable actionBtn"
                                  >
                                    OK
                                  </Button> 
                                </div>
                              </Modal>

                        <div className="myAccountRoot">
                            <h4>My Account</h4>
                            <div className="mfaHeader">
                                <div className="mfaTitle">Multi-Factor Authentication</div>
                                <div className="mfaContent">
                                    <div>Multi Factor Authentication(MFA) enhances security by requiring an additional 
                                    verification step during login, which helps prevent unauthorized access
                                    </div>
                                    {/* <div className="mfaEnableBtns">
                                        <button id="mfaEnableBtn" onClick={() => mfaSelection(true)} className={ enabled === true ? 'mfaEnabled' : 'mfaDisabled'}>Enable</button>
                                        <button id="mfaDisableBtn" onClick={() => mfaSelection(false)} className={ enabled === false ? 'mfaEnabled' : 'mfaDisabled'}>Disable</button>
                                    </div> */}

                                    <div className="mfaActionBtns">
                                        <input 
                                        type="checkbox" 
                                        checked={enabled}
                                        onChange={(e) => mfaSelection(!enabled)} 
                                        className="toggle" 
                                        id="toggle" />
                                        {/* <input type="checkbox" className="toggle" id="toggle" /> */}
                                        <label for="toggle">
                                        {/* <span onClick={() => mfaSelection(true)} className="on">ON</span>
                                        <span onClick={() => mfaSelection(false)} className="off">OFF</span> */}
                                        <span className="on">Enabled</span>
                                        <span className="off">Disabled</span>
                                        </label>
                                    </div>
                                    
                                </div>
                            </div>

                            {enabled && mfaTypes.length > 0 && 
                            <div className="mfaTypes">
                                {( mfa_method !== "null" && mfa_method !== undefined && mfa_method !== null && mfa_method !== "undefined" ) &&
                                <div className="defaultSection">
                                    <div className="defaultText">Select a preferred method</div>
                                        <Select 
                                        className={"defaultDropDown"} 
                                        variant="outlined"                                    
                                        value={JSON.stringify(selectedType)}
                                        onChange={handlePreferedMethod}
                                        >
                                            { 
                                            mfaTypes[0] && 
                                                <MenuItem 
                                                key={0} 
                                                value={JSON.stringify({"mfa_id": mfaTypes[0].mfa_id, "type": mfaTypes[0].type})} 
                                                hidden={cookies.get("totp_verified") === "false"}
                                                >
                                                    {mfaTypes[0].type.toUpperCase()}
                                                </MenuItem>
                                            }
                                            { 
                                            mfaTypes[1] && 
                                                <MenuItem 
                                                key={1} 
                                                value={JSON.stringify({"mfa_id": mfaTypes[1].mfa_id, "type": mfaTypes[1].type})} 
                                                hidden={cookies.get("email_verified") === "false"}
                                                >
                                                    {mfaTypes[1].type.toUpperCase()}
                                                </MenuItem>
                                            }
                                            { 
                                            mfaTypes[2] && 
                                                <MenuItem 
                                                key={2} 
                                                value={JSON.stringify({"mfa_id": mfaTypes[2].mfa_id, "type": mfaTypes[2].type})} 
                                                hidden={cookies.get("mobile_number_verified") === "false"}
                                                >
                                                    {mfaTypes[2].type.toUpperCase()}
                                                </MenuItem>  
                                            }
                                        </Select>
                                </div>
                                }

                                <h4>Types of Authentication</h4>
                            
                                { mfaTypes[0] && mfaTypes[0].type.toLowerCase() === "totp" && 
                                    <div className="mfaType">
                                        <div>
                                            <label>
                                            {/* <input 
                                            type="radio"
                                            id="totp"
                                            name="mfaType"
                                            ref={totp}
                                            onChange={e => {
                                                setSelectedType(mfaTypes[0]);
                                                cookies.set('mfa_type', mfaTypes[0], {
                                                    path: '/',
                                                    domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                                                });
                                            }} 
                                            /> */}
                                            <span className="mfaMethod">
                                                <span>Authenticator app verification</span>
                                                <span>For users, they will need to scan a qr code and complete the verification process to setup.</span>
                                            </span>
                                            </label>
                                        </div>
                                        <div className="actionBtns"> 
                                          { cookies.get("totp_verified") === "false" ? 
                                             <Button 
                                             variant="contained"
                                             color="primary"
                                             className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                                             onClick={() => activateMFA({"mfa_id" :  mfaTypes[0].mfa_id , "type" :  mfaTypes[0].type})}
                                             >
                                                 Add
                                             </Button> : 
                                             <Button 
                                             variant="contained"
                                             color="primary"
                                             className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                                             onClick={() => activateMFA({"mfa_id" :  mfaTypes[0].mfa_id , "type" :  mfaTypes[0].type})}
                                             >
                                                 Edit
                                             </Button>
                                          }
                                        </div>
                                </div>
                                }
                            { mfaTypes[1] && mfaTypes[1].type.toLowerCase() === "email" && 
                                <div className="mfaType">
                                    <div>
                                        <label>
                                        {/* <input 
                                        type="radio" 
                                        name="mfaType"
                                        id="email"
                                        ref={email}
                                        onChange={e => {
                                            setSelectedType(mfaTypes[1]);
                                            cookies.set('mfa_type', mfaTypes[1], {
                                                path: '/',
                                                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                                            });   
                                        }}
                                        /> */}
                                        <span className="mfaMethod">
                                            <span>Email verification</span>
                                            <span>For users, they will need to verify their email and setup.</span>
                                        </span>
                                        </label>
                                    </div>
                                    <div className="actionBtns"> 
                                          { cookies.get("email_verified") === "false" ? 
                                             <Button 
                                             variant="contained"
                                             color="primary"
                                             className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                                             onClick={() => activateMFA({"mfa_id" :  mfaTypes[1].mfa_id , "type" :  mfaTypes[1].type})}
                                             >
                                                 Add
                                             </Button> : 
                                             <Button 
                                             variant="contained"
                                             color="primary"
                                             className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                                             onClick={() => activateMFA({"mfa_id" :  mfaTypes[1].mfa_id , "type" :  mfaTypes[1].type})}
                                             >
                                                 Edit
                                             </Button>
                                          }
                                        </div>
                                </div>
                            }  
                            { mfaTypes[2] && mfaTypes[2].type.toLowerCase() === "sms" && 
                                <div className="mfaType">
                                    <div>
                                        <label>
                                        {/* <input 
                                        type="radio" 
                                        name="mfaType"
                                        id="sms"
                                        ref={sms}
                                        onChange={e => {
                                            setSelectedType(mfaTypes[2]);
                                            cookies.set('mfa_type', mfaTypes[2], {
                                                path: '/',
                                                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                                            });   
                                        }}
                                        /> */}
                                        <span className="mfaMethod">
                                            <span>SMS verification</span>
                                            <span>For users, they will need to verify through SMS and setup.</span>
                                        </span>
                                        </label>
                                    </div>
                                    <div className="actionBtns"> 
                                          { cookies.get("mobile_number_verified") === "false" ? 
                                             <Button 
                                             variant="contained"
                                             color="primary"
                                             className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                                             onClick={() => activateMFA({"mfa_id" :  mfaTypes[2].mfa_id , "type" :  mfaTypes[2].type})}
                                             >
                                                 Add
                                             </Button> : 
                                             <Button 
                                             variant="contained"
                                             color="primary"
                                             className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                                             onClick={() => activateMFA({"mfa_id" :  mfaTypes[2].mfa_id , "type" :  mfaTypes[2].type})}
                                             >
                                                 Edit
                                             </Button>
                                          }
                                        </div>
                                </div>
                            }  
                                <div>
                                    {/* <Button 
                                    variant="contained"
                                    color="primary"
                                    disabled={!selectedType}
                                    className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable proceedBtn"
                                    onClick={() => activateMFA()}
                                    >
                                        Proceed
                                    </Button> */}
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => onCancelAction()}
                                    className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable cancelBtn"
                                    >
                                        Cancel
                                    </Button>

                                </div>
                            </div>
                            }
                        </div>
                        <FooterMenu/>
                    </div>
                    </div>
                </ThemeProvider>
                </React.Fragment>
            );
        }
  }
  else{
    window.location.href = "/";
  }
}
